import React from 'react'
import PropTypes from 'prop-types'

const LoadingIndicator = ({ children, loading }) => {
  if (loading) {
    return (
      <div className="row">
        <div className="col-12">
          <p><i className="fa fa-spinner fa-pulse fa-3x fa-fw mr-3"></i> even geduld...</p>
        </div>
      </div>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

LoadingIndicator.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
}

export default LoadingIndicator
