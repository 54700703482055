import React, { Component } from 'react';
import { Link } from 'gatsby'

import CartContext from '../Context/CartContext'
import LoadingIndicator from '../loadingindicator'

export default class CartOverview extends Component {
  _handleRemove = (e, remove, item_id) => {
    e.preventDefault()
    remove(item_id)
  }

  render() {
    return (
      <CartContext.Consumer>
        {({ cartid, cart, get, addItem, removeItem }) => {
          const isEmpty = (!cart || !cart.items || cart.items.length === 0);

          return (
            <LoadingIndicator loading={false}>
              {isEmpty ? (
                <p className="mt-4">U hebt nog geen producten in uw winkelmandje</p>
              ) : ''}

              {cart && cart.items && cart.items.length > 0 && cart.items.map((item) => {
                if (item.product) {
                  return (
                    <div className="card mb-2" key={item.id}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-8">
                            <h4>{item.product.title}</h4>
                          </div>
                          <div className="col-3">
                            <h4>{Number(item.amount).toFixed(2)}<small>&euro;</small></h4>
                          </div>
                          <div className="col-1">
                            <button className="btn btn-outline-danger" title="verwijderen uit winkelmandje" onClick={e => this._handleRemove(e, removeItem, item.id)}>x</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                else {
                  return (<></>)
                }
              })}

              {(cart && cart.delivery.active) ? (
                <div className="card mt-4">
                  <div className="card-body row">
                    <div className="col-8">
                      <h4>{cart.delivery.title}</h4>
                    </div>
                    <div className="col-4">
                      <h4>{Number(cart.delivery.cost).toFixed(2)}<small>&euro;</small></h4>
                    </div>
                  </div>
                </div>
              ) : ''}

              {cart && cart.items && cart.items.length > 0 && (
                <>
                  <div className="card mt-4">
                    <div className="card-body row">
                      <div className="col-8">
                        <h4>Totaal</h4>
                      </div>
                      <div className="col-4">
                        <h4>{Number(cart.total).toFixed(2)}<small>&euro;</small></h4>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-4 offset-md-8">
                      <Link to={`/checkout/information/`} className="btn btn-primary btn-block">
                        <i className="fa fa-chevron-right mr-3" aria-hidden="true"></i>
                        <span className="">Afrekenen</span>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </LoadingIndicator>
          )

        }}
      </CartContext.Consumer>
    );
  }
}
