import React from 'react'

import Layout from '../components/layout'
import CartOverview from '../components/CartOverview'

export default class Cart extends React.Component {
  render() {
    return (
      <Layout>
        <h1 className="h1">Uw winkelmandje</h1>

        <div className="row">
          <div className="col-12">

            <CartOverview />

          </div>
        </div>
      </Layout>
    )
  }
}
